.course-container {
    padding: 20px;
}

.course-units {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.course-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    text-align: center; /* Center the text */
}

.course-card:hover {
    transform: scale(1.05);
}

.unit-image {
    width: 100%; /* Adjust image to fit card width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 5px; /* Rounded corners for the image */
    margin: 10px 0; /* Margin for spacing */
}

.unit-details {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.icon {
    display: flex;
    align-items: center;
}

.icon img {
    width: 20px; /* Adjust icon size */
    height: 20px; /* Adjust icon size */
    margin-right: 5px;
}

.rating {
    font-size: 1.2em;
}
