/* BreadcrumbOne.css */

.edu-breadcrumb-area {
    width: 100%; /* Full width */
    height: 240px; /* Adjust height */
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 0; /* Ensure no extra top margin */
    padding-top: 80px; /* Replace margin with padding for spacing */
    padding-bottom: 0;
    overflow: hidden; /* Prevent margin collapse */
}


.edu-breadcrumb-nav {
    margin-top: 10px;
}

.edu-breadcrumb {
    list-style: none;
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    color: black;
    align-items: center;
    justify-content: start;
}

.edu-breadcrumb .breadcrumb-item {
    font-size: 16px;
    color: black;
    display: inline-block;
}

.edu-breadcrumb .breadcrumb-item a {
    color: black;
    text-decoration: none;
}

.edu-breadcrumb .separator {
    margin: 10px;
}

.edu-breadcrumb .separator i {
    color: black;
}

.edu-breadcrumb .breadcrumb-item.active {
    font-size: 16px;
    color: black;
}

.breadcrumb-logo {
    max-width: 150px;
    height: auto;
    margin-left: 0;
    margin-top: 20px;
    display: block;
    margin-left: 90%;
    margin-right: 0;
}

.shape-dot-wrapper .shape-image {
    position: absolute;
}

.shape-image-1 {
    top: 0;
    left: 10%;
}

.shape-image-2 {
    top: 10%;
    right: 10%;
}

.shape-image-3 {
    bottom: 20%;
    left: 30%;
}

.shape-image-4 {
    bottom: 10%;
    right: 20%;
}

.shape-image-5 {
    bottom: 30%;
    left: 50%;
}

.shape-image-6 {
    top: 50%;
    right: 30%;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
    .edu-breadcrumb-area {
        height: auto; /* Flexible height for content */
        padding-top: 100px; /* Adjust spacing */
        padding-bottom: 10px;
    }

    .edu-breadcrumb-nav {
        display: flex; /* Use flexbox for alignment */
        align-items: center; /* Vertically align logo and breadcrumb text */
        justify-content: flex-start; /* Align items to the left */
        gap: 10px; /* Add spacing between logo and breadcrumb text */
        flex-wrap: nowrap; /* Prevent wrapping of items */
    }

    .breadcrumb-logo {
        max-width: 80px; /* Adjust logo size for mobile */
        height: auto; /* Maintain aspect ratio */
        margin: 0; /* Remove any unnecessary margins */
    }

    .breadcrumb-links {
        flex-grow: 1; /* Allow links to take remaining space */
        text-align: left; /* Align breadcrumb text to the left */
        font-size: 14px; /* Adjust font size for mobile readability */
    }

    .col-lg-4 {
        text-align: left; /* Align content to the left */
    }
}
